<template>
    <div class="c-input" :class="{ 'input-mar': inputmar }">
        <div class="input-title" v-if="title" tabindex="0">{{ title }}<i class="input-error-txt" v-show="requiredhint">*</i></div>
        <template v-if="type == 'textarea'">
            <textarea :class="{ 'input-error': dataErrorTxt }" :value="value || modelValue" :placeholder="placeholder" :type="type" :name="name" :disabled="disabled" @keyup="handleKeyup" @input="handleInput" @blur="handleBlur"> </textarea>
        </template>
        <template v-else>
            <template v-if="togglepw">
                <input v-if="showPassword" :class="{ 'input-error': dataErrorTxt, 'input-sm': sm }" :value="value || modelValue" :placeholder="placeholder" :autocomplete="autocomplete" type="text" :name="name" :disabled="disabled" :readonly="readonly" ref="input" @input="handleInput" @focus="focusEnter" @blur="deferHideEmailAndCheck" @keyup.enter="handleKeyboardEnter" @keyup="handleKeySpace" @click="hanldeClickEvent" />
                <input v-else :class="{ 'input-error': dataErrorTxt, 'input-sm': sm }" :value="value || modelValue" :placeholder="placeholder" :autocomplete="autocomplete" type="password" :name="name" :disabled="disabled" :readonly="readonly" ref="input" @input="handleInput" @focus="focusEnter" @blur="deferHideEmailAndCheck" @keyup.enter="handleKeyboardEnter" @keyup="handleKeySpace" @click="hanldeClickEvent" />
                <Icon 
                    class="pass-icon icon-style"
                    :is-responsive-name="true"
                    :name="showPassword?'sui_icon_eyehover_16px':'sui_icon_eyenormal_16px'" 
                    size="18px" 
                    color="#999"
                    @click="togglePassWord"
                />
            </template>
            <template v-else>
                <input :class="{ 'input-error': dataErrorTxt, 'input-sm': sm }" :value="value || modelValue" :placeholder="placeholder" :autocomplete="autocomplete" :type="type" :name="name" :disabled="disabled" :readonly="readonly" :maxlength="maxLength" ref="input" @input="handleInput" @focus="focusEnter" @blur="deferHideEmailAndCheck" @keyup.enter="handleKeyboardEnter" @keyup="handleKeySpace" @click="hanldeClickEvent" />
            </template>
        </template>
        <ul class="emaillist" v-show="showEmail" v-if="type == 'email'">
            <li 
                v-for="(emailVal, inx) in email" 
                :key="inx" 
                @click="selectEmail(emailVal)">
                {{ value.split('@')[0] + emailVal }}
            </li>
        </ul>
        <template v-if="lang == 'es'">
            <div class="emailcorrect" v-if="emailcorrect" v-show="showEmailSuffix" tabindex="0">
                ¿Estás seguro/a de que quieres utilizar esta dirección e-mail <a href="javascript:;" @click="SuffixEmail"> {{ correctStuffix }} </a>?
            </div>
        </template>
        <div class="input-error-txt" v-if="dataErrorTxt" v-html="dataErrorTxt" tabindex="0"></div>
    </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
import i18n from 'public/src/pages/common/biz_helper/i18n'
const { lang } = gbCommonInfo
let emialContent = ['gmail.com', 'hotmail.com', 'yahoo.es', 'hotmail.es', 'yahoo.com', 'telefonica.net', 'outlook.com', 'msn.com', 'live.com', 'icloud.com', 'outlook.es', 'me.com', 'terra.com', 'mail.ru', 'movistar.es']
let emailInputVal = ['@hotmail.com', '@gmail.com', '@yahoo.com', '@live.com', '@aol.com', '@mail.ru', '@wp.pl', '@gmx.de', '@web.de', '@msn.com']
const inputValArr = {
    en: ['@gmail.com', '@hotmail.com', '@yahoo.com', '@outlook.com', '@live.com', '@aol.com', '@mail.com', '@icloud.com', '@wp.pl', '@gmx.net'],
    us: ['@gmail.com', '@yahoo.com', '@hotmail.com', '@aol.com', '@comcast.net', '@icloud.com', '@live.com', '@msn.com', '@outlook.com', '@att.net'],
    au: ['@gmail.com', '@hotmail.com', '@bigpond.com', '@bigpond.net.au', '@yahoo.com', '@yahoo.com.au', '@live.com', '@live.com.au', '@optusnet.com', '@optusnet.com.au'],
    uk: ['@hotmail.com', '@hotmail.co.uk', '@gmail.com', '@yahoo.com', '@yahoo.co.uk', '@btinternet.com', '@live.com', '@icloud.com', '@aol.com', '@outlook.com'],
    fr: ['@gmail.com', '@hotmail.com', '@hotmail.fr', '@yahoo.com', '@yahoo.fr', '@orange.com', '@orange.fr', '@aol.com', '@live.com', '@live.fr'],
    de: ['@web.net', '@web.de', '@gmail.com', '@gmx.net', '@gmx.de', '@hotmail.com', '@hotmail.de', '@yahoo.com', '@yahoo.de', '@aol.com'],
    es: ['@gmail.com', '@hotmail.com', '@yahoo.es', '@hotmail.es', '@yahoo.com', '@telefonica.net', '@outlook.com', '@msn.com', '@live.com', '@icloud.com'],
    ar: ['@hotmail.com', '@gmail.com', '@yahoo.com', '@outlook.com', '@icloud.com', '@outlook.sa', '@live.com', '@windowslive.com', '@msn.com', '@mail.com'],
    it: ['@gmail.com', '@hotmail.com', '@hotmail.it', '@libero.com', '@libero.it', '@yahoo.com', '@yahoo.it', '@live.com', '@live.it', '@virgilio.it'],
    ru: ['@mail.ru', '@mail.com', '@yandex.com', '@yandex.ru', '@gmail.com', '@yahoo.com', '@hotmail.com', '@icloud.com', '@live.com', '@gmail.ru']
}

emailInputVal = inputValArr[lang] ? inputValArr[lang] : emailInputVal
const emojiRegex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu
export default {
    components: {
        Icon
    },
    props: {
        value: {
            default: ''
        },
        modelValue: {
            default: ''
        },
        type: {
            default: 'text'
        },
        // 额外的信息，用来做一些额外标志
        extra: {
            default: '',
        },
        name: {
            default: ''
        },
        sm: {
            default: false
        },
        title: {
            default: ''
        },
        placeholder: {
            default: ''
        },
        autocomplete: {
            default: ''
        },
        errorTxt: {
            default: ''
        },
        disabled: {
            default: false
        },
        requiredhint: {
            default: false
        },
        readonly: {
            default: false
        },
        maxLength: {
            type: Number,
            default: 100000
        },
        disabledBlur: {
            default: false
        },
        emailcorrect: {
            default: false
        },
        newpassState: {
            default: false
        },
        newpass_verify: {
            default: false
        },
        conpassState: {
            default: false
        },
        inputmar: {
            default: false
        },
        togglepw: {
            default: false
        },
        handleBlur: {
            type: Function,
            default: function() {}
        },
        filterEmoji: {
            default: false
        },
    },
    data: function() {
        return {
            lang,
            dataErrorTxt: '',
            autoEmail: emailInputVal,
            email: [],
            showEmail: false,
            autoEmailsuffix: emialContent,
            showEmailSuffix: false,
            correctStuffix: '',
            showPassword: false,
            language: {}
        }
    },
    async mounted() {
        this.language = await i18n.loadByQueue('input')
    },
    watch: {
        errorTxt: {
            handler: function(val, oldValue) {
                this.setDataErrorTxt(val)
            },
            deep: true
        }
    },
    methods: {
        updateInput (val) {
            this.$emit('update:modelValue', val)  
            this.$emit('input', val)
        },
        handleInput: function(event) {
            var self = this
            var value = event.target.value
            this.dataErrorTxt = ''
            this.showEmailSuffix = false
            if (value.length >= this.maxLength) {
                value = value.slice(0, this.maxLength)
            }

            if(this.filterEmoji && emojiRegex.test(value)) {
                value = value.replace(emojiRegex, "").replace(/(?:\u200D)/g,'')
            }
            this.updateInput(value)

            if (this.type == 'email') {
                self.email.splice(0)
                self.autoEmail.forEach(function(e, i) {
                    if (value.indexOf('@') == -1) {
                        self.email.push(e)
                    } else if (e.indexOf(value.split('@')[1]) > -1) {
                        self.email.push(e)
                    }
                })
                if (self.email.length != 0) {
                    self.showEmail = true
                } else {
                    self.showEmail = false
                }
            }
        },
        handleKeyboardEnter: function() {
            this.$emit('keyup-enter')
        },
        handleKeySpace: function() {
            this.$emit('keyup-space')
        },
        focusEnter:function(){
           this.$emit('focused')
        },
        selectEmail: function(email, target, tip) {
            const value = this.value || this.modelValue
            if (value.indexOf('@') > -1) {
                this.updateInput(value.split('@')[0] + email)
            } else {
                this.updateInput(value + email)
            }
        },
        check: function(type) {
            var value = this.$refs.input && this.$refs.input.value || ''
            var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/
            // 注册类型邮箱验证
            var patternSignUp = /^(?=.{5,100}$)^[a-zA-Z0-9]+([-_.]?([a-zA-Z0-9-_])+)*@([a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*\.)([a-zA-Z]{2,6}|[0-9]{1,3}){1}$/
            if (this.type == 'email') {
                var patt = this.extra === 'signup' ? patternSignUp : pattern
                if (!value.length) {
                    this.dataErrorTxt = this.language.SHEIN_KEY_PC_15608
                    this.$emit('change-verify', false)
                    return false
                } else if (value.length > 50) {
                    this.dataErrorTxt = this.errorTxt
                    this.$emit('change-verify', false)
                    return false
                } else if (!patt.test(value)) {
                    this.dataErrorTxt = this.language.SHEIN_KEY_PC_15695
                    this.$emit('change-verify', false)
                    return false
                } else {
                    this.dataErrorTxt = ''
                    this.$emit('change-verify', true)
                    return true
                }
            } else if (this.type == 'password') {
                if (this.newpassState) {
                    if (this.conpassState) {
                        if (!value.length) {
                            this.dataErrorTxt = this.language.SHEIN_KEY_PC_15693
                            this.$emit('change-verify', false)
                            return false
                        } else {
                            this.dataErrorTxt = ''
                            this.$emit('change-verify', true)
                            return true
                        }
                    } else {
                        this.dataErrorTxt = ''
                        this.$emit('change-verify', true)
                        return true
                    }
                } else if (this.newpass_verify) {
                    if (value.length < 8) {
                        this.dataErrorTxt = this.language.SHEIN_KEY_PC_17274
                        this.$emit('change-verify', false)
                        return false
                    } else if (!(/[a-zA-Z]{1,}/.test(value))) {
                        this.dataErrorTxt = this.language.SHEIN_KEY_PC_17275
                        this.$emit('change-verify', false)
                        return false
                    } else if (!(/[0-9]{1,}/.test(value))) {
                        this.dataErrorTxt = this.language.SHEIN_KEY_PC_17276
                        this.$emit('change-verify', false)
                        return false
                    } else {
                        this.dataErrorTxt = ''
                        this.$emit('change-verify', true)
                        return true
                    }
                } else {
                    if (!value.length) {
                        this.dataErrorTxt = this.language.SHEIN_KEY_PC_15694
                        this.$emit('change-verify', false)
                        return false
                    } else if (value.length < 6) {
                        this.dataErrorTxt = this.language.SHEIN_KEY_PC_15692
                        this.$emit('change-verify', false)
                        return false
                    } else {
                        this.dataErrorTxt = ''
                        this.$emit('change-verify', true)
                        return true
                    }
                }
            }
        },
        // 延后blur事件, 避免与click事件冲突
        deferHideEmailAndCheck: function() {
            if (this.disabledBlur) return
            var self = this
            if (this.type == 'email') {
                setTimeout(function() {
                    self.showEmail = false
                    self.$emit('blur-verifty')
                    self.check()
                    self.showEmailSuffix = false
                    if (lang == 'es' && self.check()) {
                        self.suffixErrorTip()
                    }
                }, 200)
            } else {
                self.$emit('blur-verifty')
                self.check()
            }
        },
        setDataErrorTxt: function(value) {
            if (value === this.dataErrorTxt) return
            this.dataErrorTxt = value
        },
        handleKeyup: function(e) {
            var value = e.target.value
            e.target.value = value.length >= Number(this.maxLength) ? value.slice(0, this.maxLength) : value
        },
        togglePassWord: function(){
            this.showPassword = !this.showPassword;
        },
        //相近后缀报错
        suffixErrorTip: function() {
            if (this.emailcorrect) {
                var emailsuffix = this.$refs.input.value.split('@')[1] //mailhot.com
                var match1 = function(val1, val2, len) {
                    var letter = val2.split(''),
                        count = 0
                    for (var i = 0; i < letter.length; i++) {
                        if (val1.indexOf(letter[i]) != -1) {
                            ++count
                        }
                    }
                    if (count >= len) {
                        return true
                    }
                }
                var match2 = function(str1, str2, len) {
                    for (var i = 0; i <= str2.length - len; i++) {
                        var regstr = str2.slice(i, i + len)
                        var regexp1 = new RegExp(regstr, 'g')
                        if (str1.match(regexp1)) {
                            return true
                        }
                    }
                }
                var suffixAry1 = [],
                    suffixAry2 = [],
                    suffixAry3 = []
                //1.先判断@和.之间有五位相同字母或者连续三个相同
                for (var k = 0; k < this.autoEmailsuffix.length; k++) {
                    var rightEmail = this.autoEmailsuffix[k] //hotmail.com
                    var v1 = rightEmail.split('.')[0],
                        v2 = emailsuffix.split('.')[0]
                    if (emailsuffix == rightEmail) {
                        this.showEmailSuffix = false
                        return
                    } else if (match1(v1, v2, 5) || match2(v1, v2, 3)) {
                        suffixAry1.push(rightEmail)
                    }
                }
                //console.log(suffixAry1,111111);
                //若1满足要求的有多个结果或没有结果，例如gmail和hotmail，再判断@后面的符号前三位连续两位相同
                if (suffixAry1.length == 1) {
                    this.correctStuffix = '@' + suffixAry1[0]
                    this.showEmailSuffix = true
                } else if (suffixAry1.length == 0) {
                    for (var k = 0; k < this.autoEmailsuffix.length; k++) {
                        var rightEmail2 = this.autoEmailsuffix[k]
                        var s1 = rightEmail2.split('.')[0].slice(0, 3),
                            s2 = emailsuffix.split('.')[0].slice(0, 3)
                        if (match2(s1, s2, 2)) {
                            suffixAry2.push(rightEmail2)
                        }
                    }
                    //console.log(suffixAry2,2220);
                } else {
                    for (var k = 0; k < suffixAry1.length; k++) {
                        var rightEmail3 = suffixAry1[k]
                        var s1 = rightEmail3.split('.')[0].slice(0, 3),
                            s2 = emailsuffix.split('.')[0].slice(0, 3)
                        if (match1(s1, s2, 2)) {
                            suffixAry2.push(rightEmail3)
                        }
                    }
                    //console.log(suffixAry2,2228);
                }
                //3.若2满足要求的有多个结果，例如hotmail.com和hotmail.es，那么再看.后面的，推给用户.后面含有两个及以上相同字母的
                if (suffixAry2.length == 0) {
                    return
                } else if (suffixAry2.length == 1) {
                    this.correctStuffix = '@' + suffixAry2[0]
                    this.showEmailSuffix = true
                } else {
                    var suffixdomin = emailsuffix.split('.')[1]
                    for (var k = 0; k < suffixAry2.length; k++) {
                        var rightdomin = suffixAry2[k].split('.')[1]
                        if (match1(rightdomin, suffixdomin, 2)) {
                            suffixAry3.push(suffixAry2[k])
                        }
                    }
                    //console.log(suffixAry3,3333);
                }

                if (suffixAry3.length == 1) {
                    this.correctStuffix = '@' + suffixAry3[0]
                    this.showEmailSuffix = true
                } else {
                    return
                }
            }
        },
        SuffixEmail: function() {
            var emailname = this.$refs.input.value.split('@')[0]
            this.updateInput(emailname + this.correctStuffix)
            this.showEmailSuffix = false
        },
        hanldeClickEvent () {
          this.$emit('input-click')
        }
    },
    emits: [
        'input',
        'change-verify',
        'keyup-enter',
        'keyup-space',
        'focused',
        'blur-verifty',
        'input-click',
        'update:modelValue',
    ],
}
</script>

<style lang="less">
.c-input {
    width: 100%;
    position: relative;
    &.input-mar {
        margin-top: 20px;
    }
    + .c-input {
        margin-top: 20px;
    }
    > i {
        &.warn-show {
            display: table-cell;
            color: #d53333;
        }
    }
    &.outer-inline {
        display: table;
    }
    .input-title {
        color: #666;
        &.title-inline {
            display: table-cell;
        }
    }
    input {
        width: 100%;
        height: 36px;
        padding: 0 12px;
        outline: 0;
        border: 1px solid #ccc;
        &.input-sm {
            height: 30px;
            box-sizing: border-box;
        }
        &.input-inline {
            display: table-cell;
        }
    }
    textarea {
        width: 100%;
        height: 190px;
        padding: 8px 12px;
        border: 1px solid #ccc;
        outline: 0;
        resize: none;
    }
    input, textarea {
        // 修改自动填充样式
        &:-webkit-autofill {
            box-shadow: 0 0 0 30px #fff inset;
        }
        &:hover {
            border-color: #999;
        }
        &:focus {
            border-color: #333;
        }
        &::placeholder {
            color: #ccc;
        }
        &:disabled {
            color: #e5e5e5;
            background-color: #fafafa;
            border-color: #e5e5e5;
            cursor: not-allowed;
        }
        &.input-error {
            border-color: #d53333;
        }
        &.input-error-bg{
            border-color: #d53333;
            background-color: fade(#d53333,10%);
        }
    }
    .input-error-txt {
        // display: none;
        margin-top: 6px;
        color: #d53333;
    }
    .input-success-txt{
        margin-top: 6px;
        color: #5EBD66;
    }
    .emaillist {
        position: absolute;
        top: 100%;
        z-index: @zindex-dropdown;
        width: 100%;
        border: 1px solid #ccc;
        border-top: none;
        background-color: #fff;
        color: #666;
        li {
            padding: 2px 10px;
            cursor: pointer;
        }
    }
    .emailcorrect{
        margin-top: 6px;
        line-height: 18px;
        >a{
            color: #5EBD66;
        }
    }
    .pass-icon{
        position: absolute;
        .right(0);
        top: 13px;
        padding: 8px;
        font-size: 18px;
        color: #999;
        cursor: pointer;
    }
}
.c-input{
    .icon-style{
        user-select: none;
    }
}
</style>
