<template>
    <div>
        <p v-if="title" class="dropdown-title">{{ title }}:</p>
        <div class="c-dropdown" :class="dropdownStyle" @mouseover="hoverToggleMenu(1)" @mouseout="hoverToggleMenu(0)">
            <div class="dropdown-toggle j-dropdown-keep" @click="toggleMenu">
                <span v-html="value || modelValue"></span><span class="dropdown-caret j-dropdown-keep" :class="{ 'dropdown-caret-rotate': show }"></span>
            </div>
            <ul class="dropdown-menu j-sa-good-detail-sort" v-show="show" ref="dropdownMenu" @click.stop>
                <li class="j-dropdown-keep" v-for="(val, key, index) in list" @click="changeVal(val, key)" :data-sortindex="val.value">
                    <template v-if="showNumber">
                        <span>{{ Array.isArray(list) ? key + 1 : index + 1 }}.</span>
                    </template>
                    <template v-if="Array.isArray(list)">
                        <span v-html="val.title"></span>
                    </template>
                    <template v-else>
                        <span v-html="val"></span>
                    </template>
                    <div class="sublist-menu" v-if="val.sublist && activeSublist == key">
                        <li class="j-dropdown-keep" v-for="(subVal, subKey) in val.sublist" :key="subKey" @click="changeSubVal(subVal, subKey)">
                            <span v-html="subVal.title"></span>
                        </li>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { isMobile } from 'public/src/pages/common/utils/index.js'

export default {
    props: {
        title: { default: '' },
        value: {},
        modelValue: {},
        list: { type: [Object, Array] },
        sm: { default: false },
        lg: { default: false },
        scroll: { default: false },
        showNumber: { default: false },
        hoverToggle: { default: false },
    },
    created: function() {},
    data: function() {
        return {
            show: false,
            touchstart: false,
            activeSublist: -1,
        }
    },
    computed: {
        dropdownStyle: function() {
            return {
                'c-dropdown-s': this.sm,
                'c-dropdown-l': this.lg,
                'dropdown-scroll': this.scroll
            }
        }
    },
    mounted: function() {
        var self = this
        document.addEventListener('click', function(e) {
            if (!e.target.classList.contains('j-dropdown-keep') && !navigator.userAgent.toLowerCase().match(/phone|pad|pod|iphone|ipod|ios|ipad|android/)) {
                self.show = false
            }
        })
    },
    methods: {
        changeVal: function(val, key) {
            if (val && val.sublist) {
                this.showSublist(key)
                this.$nextTick(() => {
                    this.$refs.dropdownMenu.scrollTop = 100
                })
            } else {
                this.activeSublist = -1
                this.$emit('input', Array.isArray(this.list) ? val.title : val)
                this.$emit('update:modelValue', Array.isArray(this.list) ? val.title : val)
                this.$emit('change', key, val)
                this.closeMenu()
            }
        },
        showSublist (index) {
            this.activeSublist = index
        },
        changeSubVal (val, key) {
            this.$emit('input', Array.isArray(this.list) ? val.title : val)
            this.$emit('change', key, val)
            this.closeMenu()
        },
        toggleMenu: function() {
            let self = this
            if (this.hoverToggle && !isMobile()) {
                return
            }
            console.log('click');
            if (isMobile()) {
              if (!this.touchstart) { // fix ipad
                this.show = !this.show
                self.touchstart = true
                setTimeout(() => {
                  self.touchstart = false
                }, 500)
              }
            } else {
              this.show = !this.show
            }
        },
        hoverToggleMenu: function(o) {
            if (!this.hoverToggle || isMobile()) {
                return
            }
            if (o) {
                this.show = true
            } else {
                this.show = false
            }
        },
        closeMenu () {
            if (isMobile()) {
              if (!this.touchstart) { // fix ipad
                this.show = false
                self.touchstart = true
                setTimeout(() => {
                  self.touchstart = false
                }, 500)
              }
            } else {
              this.show = false
            }
        }
    },
    emits: ['input', 'change'],
}
</script>
<style lang="less">
/* stylelint-disable */
.dropdown-title{
    color: #666;
}
.c-dropdown{
	position: relative;
    &.dropdown-scroll {
        .dropdown-menu {
            height: 300px;
            overflow-y: auto;
        }
    }
    &:hover{
        cursor: pointer;
        .dropdown-toggle, .dropdown-menu {
            border-color: #999;
        }

    }
	.dropdown-toggle{
		position: relative;
		height: 30px;
		line-height: 30px;
		border: 1px solid #ccc;
		width: 100%;
		display: inline-block;
		background: #fff;
		padding: 0 10px;
	}
    .dropdown-caret{
        position: absolute;
        .right(12px);
        width: 0;
        height: 0;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        top: 50%;
        margin-top: -1px;
        .transition(transform);
        transform: rotate(0deg);
    }
    .dropdown-caret-rotate {
        transform: rotate(180deg);
    }
	.dropdown-menu{
        // display: none;
        z-index: @zindex-dropdown;
		position: absolute;
		top: 29px;
		.left(0);
		width: 100%;
		border: 1px solid #ccc;
		border-top: 0;
		color: #999;
		background: #fff;
        max-height: 300px;
		overflow-y: scroll;
		.sublist-menu{
			border-top: 1px solid #fafafa;
			margin: 0 -10px;
			background: #f9f9f9;
		}
		li{
			height: 30px;
			line-height: 30px;
			padding: 0 10px;
			&:hover{
				&>span{
                    color: #222;
				}
			}
            &:not(:last-child) {
                border-bottom: 1px solid #fafafa;
            }
		}
	}
    &.c-dropdown-s{
      .dropdown-toggle{
       height: 24px;
       line-height: 24px;
      }
      .dropdown-menu{
       top: 24px;
       li{
            height: 24px;
            line-height: 24px;
       }
      }
    }
    &.c-dropdown-l{
      .dropdown-toggle{
       height: 36px;
       line-height: 36px;
      }
      .dropdown-menu{
       top: 36px;
       li{
            height: 36px;
            line-height: 36px;
       }
      }
    }
}
</style>