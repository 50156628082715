<template>
  <transition
    name="vue-fade"
    appear
    @after-enter="transitionEndEvent"
  >
    <div
      v-show="show"
      v-ada-modal="adaModelObj"
      class="c-modal-wrap"
      :style="iscmadalstyle && cMadalStyle"
    >
      <div
        class="she-mask"
        :class="{ 'she-opacity0': isMaskHide }"
      ></div>
      <div
        class="c-modal"
        @mousedown="clickMask($event)"
      >
        <div
          ref="dialog"
          class="modal-dialog"
          :class="styleClass"
        >
          <transition name="vue-slide-fade">
            <div
              v-show="show"
              class="modal-content"
              :style="{ background: background, padding: padding }"
            >
              <div class="modal-header">
                <slot name="headerLeft"></slot>
                <slot name="closer">
                  <i v-show="hasClose">
                    <Icon
                      v-enterkey
                      :aria-label="closeLabel"
                      tabindex="0"
                      role="button"
                      name="sui_icon_closed_14px_1"
                      class="svg-close she-close"
                      @click="cancel('icon')"
                    />
                  </i>
                </slot>
                <div class="modal-title">
                  <slot
                    ref="title"
                    name="title"
                    tabindex="0"
                  >
                  </slot>
                </div>
              </div>
              <div
                ref="body"
                class="modal-body"
              >
                <slot></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <div class="modal-btn">
                    <button
                      v-if="twobtn"
                      :class="cancelclass"
                      @click="cancel('btn')"
                    >
                      {{ canceltext }}
                    </button>

                    <button
                      v-if="isloading"
                      :class="okclass"
                      @click="ok"
                    >
                      <div
                        v-if="loading"
                        class="la-ball-pulse la-ball-pulse-white"
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div v-else>
                        {{ oktext }}
                      </div>
                    </button>

                    <button
                      v-else
                      :class="okclass"
                      @click="ok"
                    >
                      {{ oktext }}
                    </button>
                  </div>
                </slot>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
let language = {}
let lang = 'en'

if (typeof window === 'object') {
  language = gbCommonInfo.language
  lang = gbCommonInfo.lang
}

export default {
  components: {
    Icon,
  },
  props: {
    show: { type: Boolean, default: false },
    shoplook: { type: Boolean, default: false },
    smaller: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    xlarge: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    relativeheight: { type: Boolean, default: false },
    relativeWidthM: { type: Boolean, default: false },
    vertical: { type: Boolean, default: true },
    twobtn: { type: Boolean, default: true },
    isquickv: { type: Boolean, default: false },
    background: { type: String, default: '' },
    padding: { type: String, default: '' },
    // 为true时无法通过点击遮罩层关闭modal
    force: { type: Boolean, default: false },

    //是否有顶部关闭按钮
    hasClose: { type: Boolean, default: true },

    // 是否使用loading效果
    isloading: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: false },

    //弹窗是否为全屏
    fullscreen: { type: Boolean, default: false },

    oktext: {
      type: String,
      default: language.SHEIN_KEY_PC_15216,
    },
    canceltext: {
      type: String,
      default: language.SHEIN_KEY_PC_15748,
    },

    okclass: { type: String, default: 'she-btn-black' },
    cancelclass: { type: String, default: 'she-btn-white' },
    adaModel: { type: Object, default: () => null },
    transEndEvent: { type: Function, default: undefined },
    iscmadalstyle: { type: Boolean, default: false }
  },
  data() {
    return {
      closeLabel: language.SHEIN_KEY_PC_15639,
      isMaskHide: false,
      loading: false,
      scrollTop: 0
    }
  },
  computed: {
    styleClass() {
      return {
        'c-quickv': this.isquickv,
        'vertical-center': this.vertical,
        'modal-xs': this.smaller,
        'modal-sm': this.small,
        'modal-lg': this.large,
        'modal-xlg': this.xlarge,
        'modal-full': this.full,
        'modal-full-screen': this.fullscreen,
        'modal-shoplook': this.shoplook,
        'modal-relative-height': this.relativeheight,
        'modal-relative-width-m': this.relativeWidthM,
      }
    },
    cMadalStyle(){
      const elements = Array.from(document.querySelectorAll('*'))
      const zIndexes = elements.map(el => parseFloat(window.getComputedStyle(el).zIndex)).filter(zIndex => !isNaN(zIndex))
      const highestZIndex = Math.max(...zIndexes)
      const result = {
        'z-index': highestZIndex + 1
      }
      return result
    }
  },
  watch: {
    show(value) {
      var self = this

      if (value) {
        if (!this.vertical) {
          if (
            !(
              lang == 'ar' &&
              (!!window.ActiveXObject || 'ActiveXObject' in window)
            )
          ) {
            // IE浏览器, ar站打开弹窗会滚回头部
            document.body.className += ' modal-open'
          }

          if (lang == 'ar') {
            document.addEventListener('scroll', self.preventScroll)
          }
        }
        if (this.appendToBody) {
          document.body.appendChild(this.$el)
        }
      } else {
        window.setTimeout(function () {
          self.isMaskHide = false
          if (!self.vertical) {
            document.body.className = document.body.className.replace(
              /\s?modal-open/,
              ''
            )
            if (lang == 'ar') {
              document.removeEventListener('scroll', self.preventScroll)
            }
          }
        }, 500 || 0)
        if (this.appendToBody) {
          document.body.removeChild(this.$el)
        }
      }
    },
  },
  mounted() {
    if (!this.vertical) {
      var scroll_bar_width = 17
      var root = document.body
      var width_txt
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop

      if (typeof window.innerWidth == 'number') {
        scroll_bar_width = window.innerWidth - root.clientWidth
      }

      width_txt =
        '.modal-open { border-right:' +
        scroll_bar_width +
        'px solid transparent }'

      if (document.all) {
        // document.createStyleSheet(url)
        window.style = width_txt
        document.createStyleSheet('javascript:style')
      } else {
        //document.createElement(style)
        var style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = width_txt
        document.getElementsByTagName('HEAD').item(0).appendChild(style)
      }

      if (this.show) {
        if (
          !(
            lang == 'ar' &&
            (!!window.ActiveXObject || 'ActiveXObject' in window)
          )
        ) {
          // IE浏览器, ar站打开弹窗会滚回头部
          document.body.className += ' modal-open'
        }

        if (lang == 'ar') {
          document.addEventListener('scroll', this.preventScroll)
        }
      }
    }
  },
  unmounted() {
    if (!this.vertical) {
      document.body.className = document.body.className.replace(
        /\s?modal-open/,
        ''
      )
      if (lang == 'ar') {
        document.removeEventListener('scroll', this.preventScroll)
      }
    }
  },
  methods: {
    preventScroll() {
      document.documentElement.scrollTop = this.scrolTop
      document.body.scrollTop = this.scrolTop
    },
    adaModelObj() {
      const data = this.adaModel || {
        show: this.show,
        first: this.attempTrapFirstFocus,
      }
      // 等待动画结束再聚焦
      data.transition = true
      return data
    },
    transitionEndEvent() {
      try {
        if (this.transEndEvent) this.transEndEvent()
        else if (typeof this.adaModelObj.first === 'function') {
          this.adaModelObj.first()
        } else {
          this.adaModelObj.first && this.adaModelObj.first.focus()
        }
      } catch (e) {}
    },
    attempTrapFirstFocus() {
      if (this.$slots.title) {
        this.$slots.title[0] &&
          this.$slots.title[0].elm &&
          this.$slots.title[0].elm.focus()
      } else {
        this.adaModalInstance &&
          this.adaModalInstance.focusFirstDescendant(this.$refs.body)
      }
    },
    ok() {
      if (this.isloading) {
        if (this.loading) {
          return
        } else {
          this.loading = true
          this.$emit('ok')
        }
      } else {
        this.$emit('ok')
      }
    },
    cancel(opt) {
      this.$emit('cancel', opt)
    },
    clickMask(e) {
      if (!this.force && !this.isInTree(e.target)) {
        this.cancel('mask')
      }
    },
    isInTree(target) {
      return this.$refs.dialog.contains(target)
    },
  },
  emits: ['cancel', 'ok'],
}
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity */
.she-mask {
  z-index: @zindex-modal-background;
  position: fixed;
  top: 0;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  left: 0 /*rtl:ignore*/;
  background-color: fade(#222, 20%);
}
.svg-close {
  position: absolute;
  top: 8px;
  right: 8px;
}
/* 关闭按钮 */
.she-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}

.she-close:hover {
  cursor: pointer;
}

.modal-open .c-modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Container that the modal scrolls within
.c-modal-wrap {
	z-index: @zindex-modal-background;
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.c-modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // Shell div to position the modal with bottom padding
	.modal-dialog {
		position: relative;
		width: 530px;
		margin: 30px auto;
		&.modal-xs {
			width: 365px;
		}
		&.modal-sm {
			width: 400px;
		}
		&.modal-lg {
			width: 700px;
		}
		&.modal-xlg {
			width: 1000px;
		}
		&.modal-full {
			width: auto;
			margin: 30px 10px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		&.modal-shoplook{
			width: 740px;
			height: 720px;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 10px;
				height: 1px;
			}
			&::-webkit-scrollbar-thumb{
				border-radius: 10px;
				box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
				background: rgb(171,171,171);
			}
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
				border-radius: 10px;
				background: #EDEDED;
			}
			.modal-content{
				padding: 25px;
			}
		}
		/* stylelint-disable-next-line selector-class-pattern */
		&.modal-relative-width-m {
			width: 75%;
			max-width: 950px;
		}
		&.modal-relative-height {
			height: 90%;
			.modal-content {
				height: 100%;
			}
			.modal-body {
				height: 100%;
			}
		}
		&.vertical-center {
			margin: 0 auto;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
		&.modal-full-screen{
			margin: 0;
			width: 100%;
			height: 100%;
			.modal-content{
				min-height: 100%;
				padding: 0;
			}
			.modal-header{
				max-height: 0;
			}
			.modal-title{
				>div{
					margin: 0;
				}
			}
			.she-close{
				display: none;
			}
		}
	}

	// Actual modal
	.modal-content {
    position: relative;
    padding: 40px 50px 30px 50px;
    background: #fff;
    background-clip: padding-box;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // Remove focus outline from opened modal
    // outline: 0;
	}

	// Modal header
	// Top section of the modal w/ title and dismiss
	.modal-header {
    text-align: center;
		max-height: 60em;
		overflow-y: auto;
		.clearfix();
		.modal-header-left {
			position: absolute;
			top: 13px;
			left: 54px;
			font-size: 14px;
			color: #222;
		}
	}

	// Title text within header
	.modal-title {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    &>div {
      margin-bottom: 15px;
    }
      .bind-mailbox {
          font-size: 12px;
          margin-top: 10px;
          color: #d53333;
      }
      .redfont {
        color: #d53333;
        font-size: 14px;
        font-weight: normal;
      }
		.black-mask-info{
			font-size: 14px;
			word-break: break-word;
			white-space: normal;
		}
	}

	// Modal body
	// Where all modal content resides (sibling of .modal-header and .modal-footer)
	.modal-body {
    position: relative;
			line-height: 1.2;
			.she-text{
				text-align: center;
			}
	}

	// Footer (for actions)
	.modal-footer {
    text-align: center;
    .modal-btn {
      margin-top: 28px;
    }
    .clearfix(); // clear it in case folks use .pull-* classes on buttons

    [class^="she-btn"] {
		min-width: 160px;
    }

    // Properly space out buttons
    [class^="she-btn"] + [class^="she-btn"] {
      .margin-l(20px);
      margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
    // and override it for block buttons as well
    .she-btn-block + .she-btn-block {
      .margin-l(0);
    }
	}
}
.fail-guide-modal {
	.modal-title {
		font-weight: bold;
		font-size: 18px;
		color: #222;
		line-height: 20px;
	}
	.footer-btn {
		width: 335px;
		font-size: 16px;
		font-weight: bold;
		text-transform: capitalize;
		white-space: nowrap;
		overflow: hidden;
		&.mt-16 {
			margin-top: 16px;
		}
	}
}
</style>
